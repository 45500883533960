import React, { useState } from 'react'
import Img from 'gatsby-image'
import tw, { styled } from 'twin.macro'

import Btn from './button.js'

const Teachers = ({headline, copy, cta_link, cta_title, image_desktop, image_mobile, image_title}) => {

  const HeroWrapReverse = tw.div`
  flex
  flex-col
  justify-between
  md:flex-row
  py-16
  md:items-center

  `
  const HeroTextReverse = tw.div`
  px-6
  text-center
  my-auto
  md:w-2/5
  md:px-0
  md:text-right
  md:text-sm
  `

  const HeroImgReverse = tw.div`
  md:pr-6
  pb-8
  md:w-3/5
  lg:pr-8
  xl:pr-40
  xl:pt-4
  `

  const sources = [
    image_mobile,
    {
      ...image_desktop,
      media: `(min-width: 768px)`,
    }
  ]

  return(
    <HeroWrapReverse>
      <HeroImgReverse>
        <Img fluid={sources} style={{ height: '30rem', margin: 'auto 0' }} imgStyle={{width: '100%', height: '100%', objectFit: 'contain'}}/>
      </HeroImgReverse>
      <HeroTextReverse>
        <h1 tw="md:text-3xl xl:text-4xl">{headline}</h1>
        <p>{copy}</p>
        <Btn link={cta_link} text={cta_title} />
      </HeroTextReverse>
    </HeroWrapReverse>
  )
}

export default Teachers
