import React from 'react'
import Img from 'gatsby-image'
import tw, { styled } from 'twin.macro'

import Btn from './button.js'

const Testimonials = ({headline, image, image_alt, testimonial, name}) => {

  const TestContainer = tw.div`
  xl:max-w-32
  max-w-sm
  px-3
  xl:px-1
  flex-shrink-0
  align-top
  lg:pb-0
  last:pb-0
  pt-32
  pb-12
  `


return (

    <TestContainer> 
      <div tw="bg-cylightblue py-5 rounded shadow-sm relative pt-20 h-full w-full">
        <div tw="w-1/2 absolute transform -translate-y-48 translate-x-1/2"><Img fluid={image} alt={image_alt} /></div>
        <p tw="text-center px-6 xl:pt-3 text-sm">{testimonial}</p>
        <h5 tw="text-center py-2 xl:pt-0"><strong>{name}</strong></h5>

      </div>
    </TestContainer>
  )

}

export default Testimonials
