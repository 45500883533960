import React from 'react'
import Img from 'gatsby-image'
import tw from 'twin.macro'

const ImageBox = ({imagev, imagev_alt, imageh1, imageh1_alt, imageh2, imageh2_alt}) => {

    const ImageWrapper = tw.div`
    flex
    flex-col-reverse
    md:flex-row
    w-full
    max-w-full
    align-top
    pt-3
    pb-10
    justify-center
  `

    return(
    <ImageWrapper>
        <div tw="flex flex-col md:w-6/12 p-2">
            <div tw="w-full mb-4">
            <Img fluid={imageh1} alt={imageh1_alt} tw="object-cover rounded" />
            </div>
            <div tw="w-full">
            <Img fluid={imageh2} alt={imageh2_alt} tw="object-cover rounded" />
            </div>
        </div>
        <div tw="md:w-5/12 p-2">
            <Img fluid={imagev}
                alt={imagev_alt}
                tw="bg-cover bg-center h-full rounded"
              />
        </div>
    </ImageWrapper>
    )
      
}

export default ImageBox;