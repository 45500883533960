import React from "react"
import { graphql } from "gatsby"
import tw from 'twin.macro'


import Layout from "../components/layout"
import styles from "../utils/index.css"
import Hero from "../components/hero"
import HeroReverse from "../components/hero_reverse"
import Teachers from "../components/teachers"
import Testimonials from "../components/testimonials"
import Community from "../components/community"
import SEO from "../components/seo"


const Index = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title
  const test = data.testimonials.edges
  const TestWrap = tw.div`
    pt-10
    pb-20
    bg-gray-200
    relative
    items-center
  `
  const TestFlex = tw.div`
  flex
  flex-col
  justify-center
  lg:justify-around
  lg:flex-row
  lg:flex-wrap
  items-center
  lg:items-stretch
  `


  return (

    <Layout location={location} title={siteTitle} logoImage={data.logoImage}>
      <SEO title={data.webDescription.homeDescription} description={data.metaDescription.metaData} />
      <Hero 
        headline={data.herocontent.headline} 
        copy={data.herocontent.copy.copy} 
        cta_link={data.herocontent.ctaLink} 
        cta_title={data.herocontent.ctaTitle} 
        image={data.heroimage.fluid} 
        image_title={data.heroimage.description} 
      />
      <HeroReverse 
        headline={data.coursescontent.headline} 
        copy={data.coursescontent.copy.copy} 
        cta_link={data.coursescontent.ctaLink} 
        cta_title={data.coursescontent.ctaTitle} 
        image={data.coursesimage.fluid} 
        image_title={data.coursesimage.description} 
      />
      <Hero 
        headline={data.teamcontent.headline} 
        copy={data.teamcontent.copy.copy} 
        cta_link={data.teamcontent.ctaLink} 
        cta_title={data.teamcontent.ctaTitle} 
        image={data.teamimage.fluid} 
        image_title={data.teamimage.description} 
      />
      <Teachers
        headline={data.teacherscontent.headline} 
        copy={data.teacherscontent.copy.copy} 
        cta_link={data.teacherscontent.ctaLink} 
        cta_title={data.teacherscontent.ctaTitle} 
        image_desktop={data.teachersimage.fluid} 
        image_mobile={data.teachersimagemobile.fluid}  
        image_title={data.teachersimage.description} 
      />
      <TestWrap>
        <div className="testimonials">
        <h1 tw="text-center md:text-3xl xl:text-4xl">Testimonials</h1>
          <TestFlex>
            {test && test.map(({node}) => {
              return(
                <Testimonials 
                  testimonial={node.review.review} 
                  name={node.name} 
                  image={node.image.backgroundImage.fluid} 
                  image_alt={node.image.backgroundImage.headline} 
                  key={node.name}
                />
              )
            })}
          </TestFlex>
          </div>
        </TestWrap>
      <Community 
        headline={data.community.headline} 
        copy={data.community.copy.copy} 
        ctaTitle={data.community.ctaTitle} 
        ctaLink={data.community.ctaLink} 
        imagev={data.communityvertical.fluid} 
        imagev_alt={data.communityvertical.description} 
        imageh1={data.communityh1.fluid} 
        imageh1_alt={data.communityh1.description} 
        imageh2={data.communityh2.fluid} 
        imageh2_alt={data.communityh2.description}
        cta2={data.community.cta2}
        cta2link={data.community.cta2Link}
      />
    </Layout>
  )
}

export default Index

export const data = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
    metaDescription: contentfulSeoMetadata(title: {eq: "Home > Meta Description"}) {
      metaData
    }
    webDescription:contentfulWebsiteDescription {
      homeDescription
    }
    logoImage:contentfulAsset(title: {eq: "logo"}) {
      fluid(quality: 100, maxWidth: 300) {
        ...GatsbyContentfulFluid
      }
    }
    heroimage:contentfulAsset(title: {eq: "Illustration of two people learning Chinese"}) {
      title
      description
      fluid(maxWidth: 800, quality: 80){
         ...GatsbyContentfulFluid
      }
    }
    herocontent:contentfulLayoutCopy(title: {eq: "Home > Hero Content"}) {
      id
      copy {
        copy
      }
      headline
      ctaLink
      ctaTitle
    }
    coursesimage:contentfulAsset(title: {eq: "Course Description Image"}) {
      title
      description
      fluid(maxWidth: 500, quality: 80){
         ...GatsbyContentfulFluid
      }
    }
    coursescontent:contentfulLayoutCopy(title: {eq: "Home > Course Description"}) {
      id
      copy {
        copy
      }
      headline
      ctaLink
      ctaTitle
    }
    teamimage:contentfulAsset(title: {eq: "Culture Yard Team Image"}) {
      title
      description
      fluid(maxWidth: 500, quality: 80){
         ...GatsbyContentfulFluid
      }
    }
    teamcontent:contentfulLayoutCopy(title: {eq: "Home > Who We Are"}) {
      id
      copy {
        copy
      }
      headline
      ctaLink
      ctaTitle
    }
    teachersimage:contentfulAsset(title: {eq: "Meet our Teachers"}) {
      title
      description
      fluid(maxWidth: 500, quality: 80) {
         ...GatsbyContentfulFluid
      }
    }
    teachersimagemobile:contentfulAsset(title: {eq: "Meet our Teachers Mobile"}) {
      title
      fluid(maxWidth: 500, quality: 80) {
         ...GatsbyContentfulFluid
      }
    }
    teacherscontent:contentfulLayoutCopy(title: {eq: "Home > Our Teachers"}) {
      id
      copy {
        copy
      }
      headline
      ctaLink
      ctaTitle
    }
    testimonials:allContentfulTestimonial {
      edges {
        node {
          name
          review {
            review
          }
          image {
            headline
            backgroundImage {
              fluid {
                ...GatsbyContentfulFluid
              }
            }
          }
        }
      }
    }
    community:contentfulLayoutCopy(title: {regex: "/Home > Community/"}) {
      headline
      ctaTitle
      ctaLink
      cta2
      cta2Link
      copy {
        copy
      }
    }
    communityvertical:contentfulAsset(title: {eq: "Community vertical"}) {
      description
      fluid(maxWidth: 500, quality: 80){
        ...GatsbyContentfulFluid
      }
    }
    communityh1:contentfulAsset(title: {eq: "Community horizontal 1"}) {
      description
      fluid(maxWidth: 500, quality: 80){
        ...GatsbyContentfulFluid
      }
    }
    communityh2:contentfulAsset(title: {eq: "Community horizontal 2"}) {
      description
      fluid(maxWidth: 500, quality: 80){
        ...GatsbyContentfulFluid
      }
    }
  }
`
