import React from 'react'
import Img from 'gatsby-image'
import tw from 'twin.macro'

import Btn from './button.js'

const HeroReverse = ({headline, copy, cta_link, cta_title, image, image_title}) => {

  const HeroWrapReverse = tw.div`
  flex
  flex-col
  justify-between
  md:flex-row
  py-16
  md:items-center

  `
  const HeroTextReverse = tw.div`
  px-6
  text-center
  my-auto
  md:w-2/5
  md:px-0
  md:text-right
  md:text-sm
  `

  const HeroImgReverse = tw.div`
  md:pr-6
  pb-8
  md:w-3/5
  lg:pr-10
  xl:pr-32
  `


  return(
    <HeroWrapReverse>
      <HeroImgReverse>
        <Img fluid={image} alt={image_title}/>
      </HeroImgReverse>
      <HeroTextReverse>
        <h1 tw="md:text-3xl xl:text-4xl">{headline}</h1>
        <p>{copy}</p>
        <Btn link={cta_link} text={cta_title} />
      </HeroTextReverse>
    </HeroWrapReverse>
  )
}



export default HeroReverse

