import React from 'react'
import tw from 'twin.macro'

import Btn from './button.js'
import ImageBox from './image_box'

const Community = ({headline, copy, ctaTitle, ctaLink, cta2, cta2link, imagev, imagev_alt, imageh1, imageh1_alt, imageh2, imageh2_alt}) => {


  const Wrapper = tw.div`
  flex
  flex-col
  items-center
  py-16
  `

  const TextBox = tw.div`
    md:w-3/4
    text-center
    pb-10
    self-end
    mx-auto
    px-6
  `

  return(
      <Wrapper>
        <h1 tw="text-center md:text-3xl xl:text-4xl">{headline}</h1><TextBox>
          <p>{copy}</p>
           <Btn text={ctaTitle} link={ctaLink} />
        </TextBox>
        <ImageBox 
          imagev={imagev} 
          imagev_alt={imagev_alt} 
          imageh1={imageh1} 
          imageh1_alt={imageh1_alt} 
          imageh2={imageh2} 
          imageh2_alt={imageh2_alt} 
          />
          <Btn text={cta2} link={cta2link} />
      </Wrapper>
  )
}

export default Community
