import React from 'react'
import Img from 'gatsby-image'
import tw from 'twin.macro'
import { motion } from 'framer-motion'
import Btn from './button.js'

const Hero = ({headline, copy, cta_link, cta_title, image, image_title}) => {

  const HeroWrap = tw.div`
  flex
  flex-col-reverse
  justify-between
  md:flex-row
  py-16
  first:pb-16
  first:pt-0
  md:items-center
  `
  const HeroText = tw.div`
  px-6
  text-center
  my-auto
  md:w-2/5
  md:px-0
  md:text-left
  md:text-sm
  `

  const HeroImg = tw.div`
  md:pl-6
  pb-8
  md:w-3/5
  lg:pl-10
  xl:pl-32
  `
  
  return(
    <HeroWrap>
      <HeroText>
        <motion.div 
          initial = {{x: '-100vw'}}
          animate = {{ x: 0 }}
          transition = {{ duration: 1.5 }}
        >
          <h1 tw="md:text-3xl xl:text-4xl">{headline}</h1>
          <p >{copy}</p>
          <Btn link={cta_link} text={cta_title} />
        </motion.div>
      </HeroText>
      <HeroImg>
        <Img fluid={image} alt={image_title}/>
      </HeroImg>
    </HeroWrap>
  )
}





export default Hero



